<template>
  <div class="review">
    <div class="review__wrapper">
      <img alt="logo" class="review__logo" src="../../assets/logo.png">

      <template v-if="analysis && status">
        <div class="review__title">
          {{ title }}
        </div>

        <table class="review__table">
          <tr>
            <td style="width: 50%">
              <span v-if="ru">№ заявки</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">№ bid</span>
            </td>
            <td style="width: 50%">
              {{ analysis.id | number }}
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Лаборатория</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Laboratory</span>
            </td>
            <td>
              <span v-if="ru">Медикал Форте</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Medical Forte</span>
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">ФИО</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Full name</span>
            </td>
            <td>
              <span v-if="ru">{{ analysis | fullNameRu }}</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">{{ analysis | fullNameEn }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Дата рождения</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Date of Birth</span>
            </td>
            <td>
              {{ analysis.client.birthdate | date }}
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Пол</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Gender</span>
            </td>
            <td>
              <span v-if="ru" style="text-transform: capitalize;">{{ analysis.client.gender.label }}</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en" style="text-transform: capitalize;">{{ analysis.client.gender.value }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Адрес, страна</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Address, country</span>
            </td>
            <td>
              <span v-if="ru">{{ analysis.client.attributes.ru.address }}</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">{{ analysis.client.attributes.en.address }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Тип документа</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Document type</span>
            </td>
            <td>
              <span v-if="ru">{{ docType.ru }}</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">{{ docType.en }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Номер документа</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Document number</span>
            </td>
            <td>
              <span v-if="ru">{{ docInfo }}</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">{{ docInfoEn }}</span>
            </td>
          </tr>
          <tr v-if="ru">
            <td>
              <span v-if="ru">СНИЛС</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Medical insurance</span>
            </td>
            <td>{{ docSnils | snils }}</td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Дата заказа</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Order date</span>
            </td>
            <td>
              {{ analysis.order_date | datetime }}
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Дата получения</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Date of receiving</span>
            </td>
            <td>
              {{ analysis.issue_date | datetime }}
            </td>
          </tr>
          <tr class="review__table-tr-bold">
            <td>
              <span v-if="ru">Результат</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Result</span>
            </td>
            <td>
              <span v-if="ru">{{ Number(analysis.pcr) === 0 ? 'Не обнаружено' : 'Обнаружено' }}</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">{{ Number(analysis.pcr) === 0 ? 'Not detected' : 'Detected' }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span v-if="ru">Срок годности</span>
              <span v-if="ru && en"> / </span>
              <span v-if="en">Shelf life</span>
            </td>
            <td>
              {{ analysis.expiry_date | datetime }}
            </td>
          </tr>
        </table>
      </template>

      <div class="review__title" v-if="status === false">
        Срок годности данного результата истек
      </div>
    </div>
  </div>
</template>

<script>
import {qr} from '@/api/analysis/qr'

export default {
  name: "ReviewPcr",
  data() {
    return {
      analysis: null,
      status: null,
    }
  },
  filters: {
    number: function (value) {
      return value.toString().padStart(7, "0");
    },
    fullNameRu: function (value) {
      let result = '';

      if (value.client.attributes.ru.surname) {
        result += value.client.attributes.ru.surname + ' ';
      }

      if (value.client.attributes.ru.name) {
        result += value.client.attributes.ru.name + ' ';
      }

      if (value.client.attributes.ru.patronymic) {
        result += value.client.attributes.ru.patronymic;
      }

      return result;
    },
    fullNameEn: function (value) {
      let result = '';

      if (value.client.attributes.en.surname) {
        result += value.client.attributes.en.surname + ' ';
      }

      if (value.client.attributes.en.name) {
        result += value.client.attributes.en.name + ' ';
      }

      return result;
    },
  },
  computed: {
    ru() {
      const lang = this.analysis ? this.analysis.lang.value : null;

      return lang === 'ru' || lang === 'ru_en';
    },
    en() {
      const lang = this.analysis ? this.analysis.lang.value : null;

      return lang === 'en' || lang === 'ru_en';
    },
    doc() {
      const documents = this.analysis.client.documents.filter(item => item.type.value === this.analysis.doc)

      if (documents && documents.length) {
        return documents[0];
      }

      return null;
    },
    docEn() {
      const documents = this.analysis.client.documents.filter(item => item.type.value === this.analysis.doc_en)

      if (documents && documents.length) {
        return documents[0];
      }

      return null;
    },
    docType() {
      let en = null;
      
      if (this.docEn) {
        en = this.docEn.type.value.charAt(0).toUpperCase() + this.docEn.type.value.slice(1);
        en = en.replace('_', ' ');
      }
      
      return {
        ru: this.doc ? this.doc.type.label : null,
        en: en,
      };
    },
    docInfo() {
      if (this.doc) {
        return `${this.doc.series} ${this.doc.number}`;
      }

      return null;
    },
    docInfoEn() {
      if (this.docEn) {
        return `${this.docEn.series} ${this.docEn.number}`;
      }

      return null;
    },
    docSnils() {
      const documents = this.analysis.client.documents.filter(item => item.type.value === 'snils')

      if (documents && documents.length) {
        return documents[0].number;
      }

      return null;
    },
    title() {
      let title = '';

      if (this.ru) {
        title += 'ОПРЕДЕЛЕНИЕ РНК КОРОНАВИРУСА SARS-COV-2 АНАЛИЗАТОР ПЦР CFX 96 (Real-Time System)';
      }

      if (this.ru && this.en) {
        title += ' / ';
      }

      if (this.en) {
        title += 'DETERMINATION OF RNA OF CORONAVIRUS SARS-COV-2 BY CFX 96 PCR ANALYZER (Real-Time System)';
      }

      return title;
    },
  },
  created() {
    this.getAnalysis();
  },
  methods: {
    getAnalysis() {
      qr(this.$route.params.id, this.$route.query.token, 'pcr')
          .then(response => {
            this.analysis = response.data.data;
            this.status = true;
          })
          .catch(() => this.status = false);
    }
  },
}
</script>

<style lang="scss" scoped>
.review {
  background-color: white;
  margin: 0;
  padding: 24px;
  min-height: 100vh;

  &__wrapper {
    margin: 0 auto;
    justify-content: center;
  }

  &__logo {
    width: 160px;
    margin: 0 auto 5px auto;
  }

  &__title {
    size: 13px;
    text-align: center;
    font-weight: 500;
    color: #1D293F;
    margin: 0 auto 5px auto;
  }

  &__table {
    size: 13px;
    font-weight: 400;
    color: #1D293F;
    width: 100%;

    & td {
      padding: 8px 12px;
      border: 1px solid #D5D9E1;
    }

    &-tr-bold {
      font-weight: 700;
    }
  }
}
</style>
